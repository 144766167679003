.pro-sidebar > .pro-sidebar-inner {
	background: #215096 !important;
}
.pro-sidebar
	.pro-menu.submenu-bullets
	.pro-menu-item.pro-sub-menu
	.pro-inner-list-item
	.pro-inner-item:before {
	border: 1px solid #215096 !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
	background-color: #215096 !important;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
	background-color: #215096 !important;
}
.pro-sidebar.collapsed
	.pro-menu
	> ul
	> .pro-menu-item.pro-sub-menu
	> .pro-inner-list-item
	> .popper-inner {
	background-color: #215096 !important;
}
.popper-element[data-popper-placement^="left"] > .popper-arrow {
	border-right: 7px solid #215096 !important;
}
.popper-element[data-popper-placement^="right"] > .popper-arrow {
	border-left: 7px solid #215096 !important;
}
.pro-sidebar
	.pro-menu.shaped
	.pro-menu-item
	> .pro-inner-item
	> .pro-icon-wrapper {
	background-color: #215096 !important;
}
.pro-sidebar {
	color: #eeeeee !important;
}
.pro-sidebar .pro-menu a {
	color: #eeeeee !important;
}

.version-num {
	color: #fff;
	font-size: 12px;
	padding-left: 75%;
}
.version-num:hover {
	color: #d8d8d8;
}

.collapse-btn {
	font-size: 20px;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
}
.collapse-btn-collapsed {
	font-size: 20px;
	cursor: pointer;
	text-align: center;
	margin-top: 10px;
}
.collapse-btn:hover,
.collapse-btn-collapsed:hover {
	opacity: 0.7;
}
