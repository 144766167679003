.react-bootstrap-table th .order-4::after {
	content: "\2193";
	opacity: 0.4;
}

.react-bootstrap-table th .order-4::before {
	margin-left: 3.5px;
	content: "\2191";
	opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc::after {
	content: "\2193";
}

.react-bootstrap-table th .caret-4-desc::before {
	margin-left: 3.5px;
	content: "\2191";
	opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc::after {
	content: "\2193";
	opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc::before {
	margin-left: 3.5px;
	content: "\2191";
}

.downloadButton {
	background-color: #727cf5;
	border: none;
	color: white;
	padding: 7px 16px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 4px;
}

.downloadButton:hover {
	opacity: 0.7;
}
.downloadButton:active {
	opacity: 0.7;
	background-color: #285f2a;
}

.download-dropdown-item {
	background: none !important;
	border: none;
	padding: 0 !important;
	color: #6c757d;
	cursor: pointer;
}
