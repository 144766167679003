.dropdown-menu {
  max-height: 336px;
  overflow-y: scroll;
}


.map-search-result{
  width: 100%;
}

.map-button {
  margin-left:10px!important;
}

.map-button button {
  margin-right: 10px !important;
}

div.map-row {

  margin:10px 0px;
}

.search-result-container .as-react-table {
  width: 100%;
}

.search-result-container .as-react-table td{
 margin: 0px 0px;
 padding: 5px 10px;
}
.search-result-container table {
  margin:20px;
}

.rule-table table td{
  padding: 5px 10px;
  border: 1px solid #eef2f7;
    border-top-color: rgb(238, 242, 247);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(238, 242, 247);
    border-right-style: solid;
    border-right-width: 1px;

}
.rule-table table {
  width: 100%;
  margin:20px;
}
.rule-table td input {
  width: 100%;
  background: transparent;
  border: 1px gray solid;
  /* border-bottom: 1px black solid; */
}
.rule-table thead td  {
  color: #2196F3;
  font-weight: bold;

}
.input-group-addon .input-group-prepend {
  background-color: transparent!important;
  background: transparent!important;
}

/* .input-group {
  display: none!important;
} */
.cate-map-container h2{
 margin-left: 24px;
 margin-top: 30px;
}
.cate-map-container h3{
  margin-left: 25px;
  margin-top: -30px;
 }

.alert-implement{
  color: blue;
  margin-left: 20px;
}

.map-button-control button {

  margin:0px 10px;
}

.left-side-menu {
  z-index: 99999999;
}
.map-button-control {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.cate-map-container .asrt-page-length {
  display: flex!important;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: -20px;
  align-items: center!important;
}

.asrt-page-length select{
  padding: 1px 1px;
  width: 50px;
}

.cate-map-container a{
  text-decoration: underline;
 }

 .cate-map-container .table_filter {
   margin-top: 20px;
   margin-right: 80px!important;
 }
 .cate-map-container hr {
  color: black;
}

.map-select-all button {
  margin: 0px 10px;
  margin-left: 30px;
}
.map-select-all {
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
}

.cate-map-container tr.loaded {
  color: blue;
  font-weight: bold;
}

#dropdown-basic-button {
  width:100%;
}

.tableau-nav>ul {
  margin-left: 20px;
}
