.page-title {
	position: "relative";
	top: "-15px";
	margin-bottom: 30px;
}
.task-dropdown-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
}

.task-dropdown-field {
	margin: 15px;
}

.task-dropdown {
	max-height: 30vh;
	overflow: auto;
}

.task-dropdown-title {
	font-size: 14px;
	font-weight: bold;
	margin-top: 7px;
	position: relative;
}
.task-dropdown-title .content {
	font-weight: normal;
}

.task-dropdown-title-sm {
	font-size: 12px;
	font-weight: bold;
	margin-top: 7px;
	position: relative;
}

.subDomains {
	width: 20%;
	margin-left: 18px;
}
.subDomains button {
	line-height: 1.2;
}

.task-dropdown-selected {
	overflow-wrap: normal;
}

.dropdown-toggle {
	width: 100%;
	white-space: normal !important;
	word-break: break-all !important;
}

.dropdown-menu {
	width: 100%;
}

.index-download {
	display: none;
}

.volTable {
	width: 100%;
	max-height: 300px;
	overflow-y: scroll;
	padding-left: 20px;
	padding-right: 10px;
	margin-top: 10px;
}
.volTable table {
	border-collapse: collapse;
	width: 100%;
	font-size: 16px;
}
.volTable table td,
.volTable table th {
	border: 1px solid rgb(190, 190, 190);
	padding: 8px;
}

.collapsible,
.collapsible-active {
	color: #515050;
	font-size: 16px;
	cursor: pointer;
	padding: 10px;
	width: 100%;
	text-align: center;
}
.collapsible,
.collapsible-active:hover {
	opacity: 0.7;
}
.collapsible:after {
	content: "\002B";
	color: #515050;
	font-weight: bold;
	float: right;
	margin-left: 5px;
	line-height: 1;
}
.collapsible-active:after {
	content: "\2212";
	color: #515050;
	font-weight: bold;
	float: right;
	margin-left: 5px;
	line-height: 1;
}

.cloud-download-btn {
	width: 10%;
	margin-left: 20px;
	margin-top: 10px;
}
.cloud-download-btn button {
	line-height: 1.2;
}

.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin: 0 auto;
}
.loader-wrapper {
	display: flex;
	align-items: center;
	height: 600px;
	width: 100%;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.subOptions-wrapper {
	display: flex;
	flex-direction: row;
}

.search-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.searchbox {
	position: relative;
	margin-bottom: 50px;
}

.searchbox .search_btn {
	width: 50px;
	height: 50px;
	position: absolute;
	right: 0;
	top: 0;
	border-radius: 50%;
	background: #8faedc;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;
}

.searchbox input {
	outline: none;
	border: 0;
	border: 1px solid #8faedc;
	border-radius: 50px;
	padding: 10px 20px;
	width: 500px;
	height: 50px;
	font-size: 14px;
	color: #000000;
}

.emailfeed-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	gap: 15px;
}

.emailfeed-wrapper .column {
	display: flex;
	flex-direction: column;
	max-width: 25%;
	flex: 1 1 auto;
}

.grid-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 10px;
}
.grid-wrapper .dropdown-toggle,
.grid-wrapper .dropdown-menu,
.grid-wrapper .form-control {
	width: 85%;
}

.composite-nav a {
	font-size: 16px;
}

.billboard-download {
	width: 20%;
	margin-bottom: 5px;
	margin-right: 10px;
	display: inline-block;
}

.clear-icon {
	position: absolute;
	right: 23%;
	top: 24%;
	cursor: pointer;
}

.search-icon {
	position: absolute;
	top: 24%;
	left: 10px;
	color: #b5b5b5;
}

.filters-container {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	gap: 10px;
	margin-top: 15px;
}

.search-bar input {
	width: 80%;
	height: calc(2.5rem + 2px);
	padding-left: 40px;
	font-size: 1rem;
}

.search-btn {
	position: absolute;
	top: -18px;
	left: 85%;
}
