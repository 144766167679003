text {
	font-weight: 300;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serf;
	font-size: 14px;
}

.node rect {
	stroke: #333;
	fill: #fff;
	cursor: pointer;
}

.edgePath path {
	stroke: #333;
	fill: #333;
	stroke-width: 1.5px;
}

.node text {
	pointer-events: none;
}

/* This styles the title of the tooltip */
.tipsy .name {
	font-size: 1.5em;
	font-weight: bold;
	color: #60b1fc;
	margin: 0;
}

/* This styles the body of the tooltip */
.tipsy .description {
	font-size: 1.2em;
}

div.tooltip {
	position: absolute;
	text-align: center;
	width: 60px;
	height: 28px;
	padding: 2px;
	font: 12px sans-serif;
	background: lightsteelblue;
	border: 0px;
	border-radius: 8px;
	pointer-events: none;
}

.task-visualization-wrapper {
	margin-top: 15px;
}

.task-svg-wrapper {
	display: flex;
	justify-content: center;
}

.task-flow-dashboard {
	width: 100%;
	height: 700px;
}

.specific-task-wrapper {
	padding-left: 12px;
}

.specific-task-wrapper button {
	margin-bottom: 25px;
}

.specific-task-section {
	margin-top: 20px;
	margin-bottom: 20px;
}

.task-dashboard-btn {
	margin-bottom: 35px;
}

.task-dropdown-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
}

.task-dropdown-field {
	margin: 15px;
}

.task-dropdown {
	max-height: 30vh;
	overflow: auto;
}

.task-detail {
	max-height: 660px;
	overflow: auto;
}

.airstream-container {
	max-height: 1100px;
	overflow: auto;
}

.result-active {
	display: block !important;
}

.result-hidden {
	display: none !important;
}

.result-chart-container {
	overflow-x: auto;
}

.dag-modal .modal-content {
	width: 100%;
	padding: 5%;
	padding-left: 10%;
}

.modal-btns {
	margin-top: 10%;
	display: "flex";
	flex-wrap: wrap;
	justify-content: space-around;
}

.dag-title {
	display: flex;
}

.title-btn {
	margin-left: 15px;
	margin-bottom: 5px;
}

.execution-header {
	margin-bottom: 25px;
}

.execution-list {
	margin-top: 15px;
}

.select-button {
	margin-top: 15px;
}

.execute-modal-input {
	width: 100%;
	margin-bottom: 20;
}

.datepicker-failed {
	color: #f54242 !important;
}
.datepicker-finished {
	color: #4ce060 !important;
}
.datepicker-running {
	color: #ffa500 !important;
}
