.chart-search-text {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.chart-panel-options {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.chart-panel-checkbox {
	margin: 10px;
}

.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin: 0 auto;
}
.loader-wrapper {
	display: flex;
	align-items: center;
	height: 600px;
	width: 100%;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
