.rule-btns {
	width: 100% !important;
}

.rule-btn {
	margin-left: 20px;
	margin-bottom: 0.2rem;
}

.rule-modal .modal-content {
	width: 130%;
	padding: 5%;
	padding-left: 10%;
}

.rule-creation-title {
	font-weight: bold;
}

.rule-creation-row {
	margin-bottom: 15px;
}

.micon {
	font-size: 18px;
}

.close-icon {
	cursor: pointer;
}

.my-card-header {
	display: flex;
	flex-direction: row;
}

.spinner-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 600px;
	width: 100%;
}
.spinner {
	width: 120px;
	height: 120px;
	border: 16px solid #f3f3f3;
	border-top: 16px solid #3498db;
	border-radius: 50%;
	animation: spin 2s infinite linear;
}
@keyframes spin {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.react-bs-table-no-data {
	text-align: center;
}

.scroll-table {
	max-height: 650px;
	overflow-y: scroll;
}

.edit-wrapper {
	display: flex;
	flex-direction: row;
}

.mytooltip {
	display: inline-block;
	position: relative;
	text-transform: none;
}
.mytooltip-debug {
	display: inline-block;
	position: absolute;
	right: 80px;
	top: 100px;
	text-transform: none;
}

.mytooltip .tooltiptext,
.mytooltip2 .tooltiptext,
.mytooltip-debug .tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 10px;
	position: absolute;
	right: 0;
	z-index: 1;
}
.mytooltip2 .tooltiptext {
	float: right;
	right: 0;
	top: 55px;
}
.mytooltip-target {
	display: inline-block;
}

.mytooltip-target:hover + .tooltiptext {
	visibility: visible;
}

.export-icon {
	font-size: 20px;
	cursor: pointer;
	position: absolute;
	top: 5px;
	left: 15px;
}
.close-btn {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 25px;
	height: 25px;
	text-align: center;
	font-weight: bold;
	font-size: 1.2em;
	cursor: pointer;
}

.resizable {
	width: 200px;
	height: 180px;
	border: 1px solid;
	resize: both;
	overflow: auto;
}
