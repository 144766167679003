/* ======= DataTables ======= */
td.details-control {
	background: url("../plugins/datatables/resources/details_open.png") no-repeat
		center center;
	cursor: pointer;
}
tr.shown td.details-control {
	background: url("../plugins/datatables/resources/details_close.png") no-repeat
		center center;
}
