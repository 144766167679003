*,
::after,
::before {
	box-sizing: border-box;
}
html {
	position: relative;
	min-height: 100%;
}
body {
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	font-family: "Open Sans", arial, sans-serif;
	color: #494d55;
	font-size: 12px;
	margin-bottom: 50px;
}
.page-wrapper {
	min-height: 100%;
	margin-bottom: -50px;
}
#header {
	border-top: 5px solid #40babd;
	border-color: #75c181;
	background-color: #494d55;
	width: 100%;
	height: 129.6px;
	padding: 30px 0;
}
#header .container {
	position: relative;
	max-width: 1140px;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	overflow: hidden;
}
#header .container .branding {
	width: 100%;
	height: 33.6px;
	margin-bottom: 10px;
}
#header .container .breadcrumb {
	color: rgba(255, 255, 255, 0.5);
	width: 100%;
	position: relative;
	top: -15px;
}
#header .container .top-search-box {
	position: absolute;
	right: 15px;
	top: 15px;
}
#header .container .top-search-box .search-input {
	font-size: 14px;
	border-radius: 20px;
	background-clip: padding-box;
	padding-top: 4px;
	display: inline-block;
	width: auto;
	box-shadow: none;
	height: 40px;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	padding: 0.375rem 0.75rem;
	border: 1px solid #f0f0f0;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#header .container .top-search-box .search-input:focus {
	border-color: #616670;
}
#header .container .top-search-box .search-btn {
	background-color: none;
	width: 38px;
	height: 40px;
	color: #797f8b;
	border: none;
	position: absolute;
	right: 5px;
	top: 0px;
	margin-right: 0;
}
#header .container .branding .logo {
	font-size: 28px;
	color: rgba(255, 255, 255, 0.85);
	font-family: "Open Sans", arial, sans-serif;
	position: relative;
	top: -20px;
}
#header .container .branding .logo svg {
	width: 25px;
	height: 25px;
	color: #75c181;
	position: relative;
	top: -3px;
	left: -5px;
}
#header .container .branding .logo a {
	display: block;
	padding-top: 10px;
}
#header .container .branding .logo a .text-highlight {
	color: #75c181;
	font-weight: 500;
}
#header .container .branding .logo a .text-bold {
	font-weight: 800;
	color: #fff;
}
.cards-section {
	padding: 60px 0;
	background: #f9f9fb;
}
.text-center {
	text-align: center !important;
}
section {
	display: block;
}
.cards-section .title {
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
}
.cards-section .intro {
	margin: 0 auto;
	margin-bottom: 0px;
	max-width: 800px;
	margin-bottom: 60px;
	color: #616670;
}
.cards-section .cards-wrapper {
	max-width: 860px;
	margin-left: auto;
	margin-right: auto;
}
.cards-section .item {
	margin-bottom: 30px;
}
.offset-2 {
	margin-left: 16.666667%;
}
.col-8 {
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.cards-section .item-pink .item-inner {
	border-top: 3px solid #ea5395;
}
.cards-section .item-inner {
	padding: 45px 30px;
	background: #fff;
	position: relative;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	background-clip: padding-box;
	height: 100%;
}
.form-group {
	margin-bottom: 1rem;
}
.cards-section input {
	-webkit-appearance: none;
	overflow: visible;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
.btn-primary1,
a.btn-primary1 {
	background: #40babd;
	border: 1px solid #40babd;
	color: #fff !important;
	-moz-transition: all 0.4s ease-in-out;
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
	border-radius: 0.35em;
}
.btn-primary1:hover {
	background: #3aa7aa;
	color: #fff !important;
	border: 1px solid #3aa7aa;
}
#footer,
.footer {
	background: #26282c;
	color: rgba(255, 255, 255, 0.6);
	padding: 15px 0;
	height: 50px;
	display: block;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	padding-left: 10%;
}
#footer .container {
	max-width: 1140px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.text-center {
	text-align: center !important;
}
.small,
small {
	font-size: 80%;
	font-weight: 400;
}
.body-green a {
	color: #75c181;
}
