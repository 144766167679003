.monitor-list {
    overflow-wrap: break-word !important;
}

.monitor-status-list {
    flex-wrap: nowrap;
}

@media(max-width: 1400px) {
    .monitor-status-list {
        flex-wrap: wrap;
    }
}
